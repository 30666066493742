
function ClockGif(props) {
    return (
        <div>
            {/* <video id="pageBackground_mua1n_video" className="bg-video" role="presentation" crossorigin="anonymous" playsinline="" preload="auto" muted="" loop="" autoplay="" tabindex="-1" width="100%" height="100%" src="https://video.wixstatic.com/video/11062b_3e2f37c297534f9ead156265242fbca8/1080p/mp4/file.mp4" >

            </video> */}
            <img
                src="https://static.wixstatic.com/media/84770f_a8e9f0928bf844718bc0a55d46c54f56~mv2.gif"
                alt="" className="wimg" />
        </div>
    );
}

export default ClockGif;