
import './App.css';
import ClockGif from './Components/ClockGif';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ClockGif/>
      </header>
        <p>
          We are Fixing Some stuff we will be back soon! 
        </p>
    </div>
  );
}

export default App;
